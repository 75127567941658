let campaignFilterData = [
    { label: 'All', value: '' },
    { label: 'Exact', value: 'Exact' },
    { label: 'Phrase', value: 'Phrase' },
    { label: 'Broad', value: 'Broad' },
    { label: 'ASIN', value: 'ASIN' },
    { label: 'Ranking', value: 'Ranking' },
]


export default campaignFilterData