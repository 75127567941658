import axios from "axios";

const api = `${process.env.REACT_APP_API_BASE}/api/SB/`
const settingApi = `${process.env.REACT_APP_API_BASE}/api/setting/`
const authToken = localStorage.getItem('sellerToken')
const filterApi = `${process.env.REACT_APP_API_BASE}/api/filter/`

const headers = { Authorization: `Bearer ${authToken}`, 'Content-Type': 'application/json' };

const ApiCampaignList = (data) => {
    return axios.post(`${api}campaign/getSBCampaign`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}
const ApiUnmange = (data) => {
    return axios.post(`${settingApi}updateUnmanagedCampaign`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}
const ApiChild = (data) => {
    return axios.post(`${api}target/getTargetData`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}
const ApisaveAcos = (data) => {
    return axios.post(`${api}target/saveAcosToDB`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}
const ApisaveInAmazon = (data) => {
    return axios.post(`${api}target/updateSBTarget`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}
export { ApiCampaignList, ApiUnmange, ApiChild, ApisaveAcos, ApisaveInAmazon }
