
import { loginSchema } from '../utility/validator';
import { ApiLogin } from '../api-wrapper/auth/ApiAuth';
import {  Toast, useDispatch, useNavigate, Link, useForm, yupResolver, Controller } from '../helper/links/link';
import React,{useState,useEffect}from 'react'
// import {handleLoader} from '../redux/action/other';
import Cookies from 'js-cookie';
function Login() {
    const navigate = useNavigate()
    let dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({ resolver: yupResolver(loginSchema) });


    const [login, setLogin] = useState({
        email: '',
        password: ''
      });
    const [flag, setFlag] = useState(false);

    useEffect(() => {
      if (Cookies.get('sellerLogin')) {
        setLogin({
          email: JSON.parse(Cookies.get('sellerLogin')).email,
          password: JSON.parse(Cookies.get('sellerLogin')).password
        })
        reset({
            email: JSON.parse(Cookies.get('sellerLogin')).email,
            password: JSON.parse(Cookies.get('sellerLogin')).password
        })
        setFlag(true)
      }
    }, []);
    
  useEffect(() => {
    if (login && flag) {
      Cookies.set('sellerLogin', JSON.stringify(login))
    }
  }, [flag, login]);

    const submitHandler = (data) => {
        setLogin(data)
        // dispatch(handleLoader(true))
        ApiLogin(data)
            .then((res) => {
                if (res.isSuccess) {
                    
                    localStorage.setItem('sellerToken', res.authToken);
                    localStorage.setItem('profileId', res.profileId);
                    localStorage.setItem('userName', res.name);
                    localStorage.setItem('antonTimezone', res.timezone);
                    localStorage.setItem("radio", 'Placement');
                    Toast.success(res.message)
                    window.location.href='/'
                    
                    // dispatch(handleLoader(false))
                }
                else {
                    Toast.error(res.message)
                    // dispatch(handleLoader(false))
                }

            }).catch((err) => {
                // dispatch(handleLoader(false))
                Toast.error("Somthing went wrong")
            });

    }

    const rememberMeHandler = (e) => {
        if (e.target.checked) {
          setFlag(true)
        }
        else {
          Cookies.remove('sellerLogin', JSON.stringify(login))
          setFlag(false)
        }
      }
    
    return (
        <div className='login-page'>

            <div className="login-box">
                <h2 className="heading-login-box">Login</h2>
                <form onSubmit={handleSubmit(submitHandler)}>
                    <div className="user-box">
                        <Controller
                            control={control}
                            name="email"
                            {...register("email")}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    className="user-box-input"

                                    value={field.value}
                                    onChange={(e) => {
                                        field.onChange(e.target.value);
                                    }}
                                />
                            )}
                        />

                        <label className="user-box-label">Email</label>
                        {errors.email && <p className="error">{errors.email.message}</p>}
                    </div>
                    <div className="user-box">
                        <Controller
                            control={control}
                            name="password"
                            {...register("password")}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="password"
                                    className="user-box-input"
                                    value={field.value}
                                    onChange={(e) => {
                                        field.onChange(e.target.value);
                                    }}
                                />
                            )}
                        />
                        <label className="user-box-label">Password</label>
                        {errors.password && <p className="error">{errors.password.message}</p>}


                    </div>
              
                    <div className='remember'>
                            <input type='checkbox' checked={flag} onClick={(e) => rememberMeHandler(e)}/>
                            <label className='ps-2'>Remember me</label>
                        </div>
                    <button type="submit" className="login-box-button" >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Login
                    </button>
                </form>


            </div>

        </div>
    )
}

export default Login