import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
	ApiCustomFilter,
	ApiChild,
	ApiSaveToDb,
	ApiPlacementList
} from "../../../api-wrapper/campaign/ApiCampaignName";
import { useDispatch, useSelector } from "react-redux";
import { childBidHandler } from "../../../redux/action";
import Toast from "../../../helper/toast/Toast";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import ExpandTable from "./ExpandTable";
import campaignFilterData from "./col";
import { ProfileContext } from "../../../usecontext/useContext";
import { useContext } from "react";
import Multiselect from 'multiselect-react-dropdown';
import { ApiStore } from "../../../api-wrapper/advertising/ApiAdvertising";
import { ApiUnmange } from '../../../api-wrapper/campaign/ApiCampaignName';

function CampaignTable({ campaignId, fromDate, toDate, }) {
	const dispatch = useDispatch();
	const { minTargetCPC, setminTargetCPC, maxTargetCPC, setmaxTargetCPC, limitValue, setlimitValue, orderSwitch, orderVal, setOrderVal, setOrderSwitch } = useContext(ProfileContext);
	const [profileId, setprofileId] = useState(localStorage.getItem("profileId") || "");
	const [bulk, setBulk] = useState(false);
	const [campaignFilterName, setCampaignFilterName] = useState("");
	const [searchEL, setsearchEL] = useState("");
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [TOSPercentage, setTOSPercentage] = useState();
	const [defaultTargetAcos, setdefaultTargetAcos] = useState();

	const [arg, setArg] = useState({
		pageNo: 1,
		perPage: localStorage.getItem('perPage') || 25,
		type: 0,
		filterIds: selectedOptions,
		searchType: campaignFilterName,
		search: searchEL,
	});
	const [campaignData, setcampaignData] = useState([]);
	const [weekData, setWeekData] = useState([]);
	const [fieldName, setfieldName] = useState('');
	const [sortType, setsortType] = useState('')
	const [subval, setsubval] = useState([]);
	const [campaignIds, setCampaignIds] = useState([]);
	const [campaignNamesArr, setCampaignNamesArr] = useState([]);
	const [aovData, setaovData] = useState([])
	const [target, setTarget] = useState([]);
	const [acos, setAcos] = useState({
		TOS_ACOS: "",
		PP_ACOS: "",
		ROS_ACOS: ""
	});
	const [expandedRows, setExpandedRows] = useState([]);
	const CustomCollapsedIcon = () => <AiOutlinePlus />;
	const CustomExpandedIcon = () => <AiOutlineMinus />;
	const customExpandIcon = {
		collapsed: <CustomCollapsedIcon />,
		expanded: <CustomExpandedIcon />,
	};
	const isRowExpanded = (row) => expandedRows.includes(row.campaignName);
	const [newWeekData, setNewWeekData] = useState([]);
	const [perPage, setPerPage] = useState(localStorage.getItem('perPage') || 25);

	const [totalRecords, settotalRecords] = useState(0);
	const [radioCheck, setradioCheck] = useState(localStorage.getItem("radio") || "Placement");
	const [optionName, setOptionName] = useState([]);
	const [filterData, setfilterData] = useState([]);
	const [rowSelect, setRowSelect] = useState([]);
	const [selectALL, setSelectALL] = useState(false);
	const limitArray = [25, 100, 500, 1000];
	const paginationRowsPerPageOptions = [...limitArray, totalRecords];
	paginationRowsPerPageOptions.sort((a, b) => a - b);

	const [bestFlag, setbestFlag] = useState(false);
	const [bestArr, setBestArr] = useState([]);
	const [bestCVRCPC, setBestCVRCPC] = useState([]);
	const columns = [
		{
			name: (
				<input
					type="checkbox"
					onChange={(e) => handleSelectAll(e)}
					checked={selectALL}
				/>
			),
			width: "50px",
			cell: (row) => (
				<input
					type="checkbox"
					checked={rowSelect.includes(row.campaignId)}
					onChange={(e) => handleRowSelect(e, row.campaignId)}
				/>
			),
		},
		{
			name: "Target Acos",
			sortable: true,
			selector: (row) => row.targetAcos,
			cell: (e) => {
				return <>{`-`}</>;
			},
		},

		{
			name: "Campaigns",
			selector: (row) => row.campaignName,
			sortable: true,
			cell: (e) => {
				return <>

					<div className={e.campaignStatus == "ENABLED" ? "green_dot dot" : "red_dot dot"}>
						<p></p>
						<div>{e.campaignName}</div>
					</div>

				</>;
			},
		},

		{
			name: "Bid Adjustment",
			sortable: true,
			width: "80px",
			selector: (row) => row.keywordBid,
			cell: (e, rowIndex) => {
				return `$${e.keywordBid ? e.keywordBid : '0'}`;
			},
		},

		{
			name: "Target CPC In Amazon",
			sortable: true,
			width: "80px",
			selector: (row) => row.tagetCPCAmazon,
		},

		{
			name: "Target CPC",

			sortable: true,
			selector: (row) => row.targetCPC,
			cell: (e) => {
				return <>{`-`}</>;
			},
		},

		{
			name: "Best CPC",
			sortable: true,
			selector: (row) => row.bestCPC,
			cell: (e) => {
				return <>{`-`}</>;
			},
		},
		{
			name: "Limited CPC",

			sortable: true,
			selector: (row) => row.limitedCPC,
			cell: (e) => {
				return <>{`-`}</>;
			},
		},

		{
			name: "New Bid Adjustment",
			// selector: (row) => row.newbidAdjustment,
			selector: (row) => row.newKeywordBid,
			sortable: true,
			cell: (e) => {
				return <>{`$${e.newKeywordBid?.toFixed(2)}`}</>;
			},
		},

		{
			name: "Impressions",
			selector: (row) => row.totalImpressions,

			sortable: true,
			// width: "100px",
		},

		{
			name: "Clicks",
			selector: (row) => row.totalClicks,
			sortable: true,
			// width: "70px",
		},
		{
			name: "CTR%",
			sortable: true,
			selector: (row) => row.totalCtr,
			cell: (e) => `${e.totalCtr}%`,
		},
		{
			name: "Spend",
			sortable: true,
			selector: (row) => row.totalSpend,
			cell: (e) => `$${e.totalSpend}`,
		},
		{
			name: "Sales",
			sortable: true,
			selector: (row) => row.totalSales,
			cell: (e) => `$${e.totalSales}`,
		},
		{
			name: "Orders",
			selector: (row) => row.totalOrders,
			sortable: true,
			// width: "70px",
		},
		{
			name: "Units",
			selector: (row) => row.totalOrders,
			sortable: true,
			// width: "70px",
		},

		{
			name: "CVR%",
			sortable: true,
			selector: (row) => row.totalCvr,
			cell: (e) => `${e.totalCvr}%`,
		},

		{
			name: "ACOS%",
			sortable: true,
			selector: (row) => row.totalAcos,
			cell: (e) => `${e.totalAcos}%`,
		},
		{
			name: "CPC",

			sortable: true,
			selector: (row) => row.totalCpc,
			cell: (e) => `$${e.totalCpc}`,
		},


		{
			name: "ROAS",
			selector: (row) => row.totalRoas,
			// width: "70px",
			sortable: true,
		},

	];

	const handleRowSelect = (e, rowId) => {

		const isChecked = e.target.checked;

		if (isChecked) {

			if (!rowSelect.includes(rowId)) {
				setRowSelect([...rowSelect, rowId]);
			}
			let data = JSON.parse(localStorage.getItem('ids')) || []
			localStorage.setItem('ids', JSON.stringify([...data, rowId]))


		} else {
			let data = JSON.parse(localStorage.getItem('ids'))
			localStorage.setItem('ids', JSON.stringify(data.filter(x => x != rowId)))

			const updatedSelectedRows = rowSelect.filter((id) => id !== rowId);
			setRowSelect(updatedSelectedRows);
		}
	};

	useEffect(() => {
		localStorage.removeItem('ids')
	}, []);

	const handleSelectAll = (e) => {

		if (e.target.checked) {
			let ids = campaignData.map(x => x.campaignId)
			let prevSelect = rowSelect;
			setRowSelect([...ids, ...prevSelect])
			setSelectALL(true)

			let data = JSON.parse(localStorage.getItem('ids')) || []
			let arr = data.concat(...ids)
			localStorage.setItem('ids', JSON.stringify([...new Set(arr)]))
		}
		else {
			setSelectALL(false)
			let data = JSON.parse(localStorage.getItem('ids')) || [];

			let aSet = new Set(campaignData.map(item => item.campaignId));
			let idsNotInA = data.filter(id => !aSet.has(id));

			localStorage.setItem('ids', JSON.stringify(idsNotInA))
			setRowSelect(idsNotInA)
		}
	}

	useEffect(() => {
		let getStorage = rowSelect;
		let check = campaignData.every(x => getStorage.includes(x.campaignId));
		if (check) {
			setSelectALL(true);
		} else {
			setSelectALL(false);
		}
	}, [rowSelect, campaignData]);

	const getCampaignData = async (val) => {
		if (fromDate && toDate) {
			let data = {
				profileId: profileId,
				TOS_ACOS: acos.TOS_ACOS,
				PP_ACOS: acos.PP_ACOS,
				ROS_ACOS: acos.ROS_ACOS,
				targetSearch: target,
				fieldName: fieldName,
				sortType: sortType,
				isManage: true,
				fromDate: fromDate,
				toDate: toDate,
				...arg,
				...val,
			};
			if (!limitArray?.includes(parseInt(data?.perPage))) {
				data = {
					...data,
					perPage: "ALL"
				}
				// setPerPage(1000)

			}

			if (radioCheck == "DataTable") {
				data = {
					...data,
					filterType: "Date"
				}
			}
			else {
				if (orderSwitch) {
					data = {
						...data,
						filterType: "Orders"
					}
				}
				else {
					data = {
						...data,
						filterType: "Date"
					}
				}
			}

			try {

				const e = await ApiPlacementList(data);
				if (e?.isSuccess) {
					settotalRecords(e.totalRecords);
					if ((!limitArray?.includes(data?.perPage)) || e?.totalRecords < data?.perPage || data?.perPage == "ALL") {
						setPerPage(e.campaignsResult?.length)
						localStorage.setItem("perPage", e.campaignsResult?.length)
					}
					setAcos({
						TOS_ACOS: e.targetAcos.TOS_ACOS,
						PP_ACOS: e.targetAcos.PP_ACOS,
						ROS_ACOS: e.targetAcos.ROS_ACOS,
					})
					setTOSPercentage(e.TOSPercentage)
					setExpandedRows([])
					setCampaignIds([])
					setminTargetCPC(e.minTargetCPC)
					setmaxTargetCPC(e.maxTargetCPC)
					setlimitValue(e.limitedCPCValue?.conditions)
					setaovData(e.getAOVData)
					setdefaultTargetAcos(e.defaultTargetAcos)
					setOrderVal(e.defaultOrder || "")

					e?.campaignsResult?.forEach(item => {
						// Extract type from campaignName
						// Find corresponding record in defaults array
						let defaultRecord = e.defaultTargetAcos?.find(record => item.campaignName.includes(record.type));

						// If a matching record is found, add it to the item
						if (defaultRecord) {
							item.defaultRecord = defaultRecord;
						} else {
							item.defaultRecord = e.defaultTargetAcos?.find((data) => data.type == "All other")
						}
					});
					setcampaignData(e.campaignsResult);
				} else {
					Toast.error(e?.message);
				}
			} catch (error) {
				Toast.error("Something went wrong");
			} finally {
			}
		}
	};

	useEffect(() => {
		getCampaignData(arg)
	}, [fromDate, toDate, orderSwitch]);

	const ExpandedComponent = useCallback(
		({ data }) => {
			return (
				<div
					className="sub_table campaign_table campaign_sub"
					key={data.campaignId}
				>
					<ExpandTable
						arg={arg}
						setArg={setArg}
						campaignName={data?.campaignName}
						keywordBid={data?.keywordBid}
						placementData={data?.placementsData || []}
						weekData={data?.subDataTable}
						radioCheck={radioCheck}
						setWeekData={setWeekData}
						campaignData={campaignData}
						setsubval={setsubval}
						aovData={aovData}
						order={data?.totalOrders}
						minTargetCPC={minTargetCPC}
						maxTargetCPC={maxTargetCPC}
						limitValue={limitValue}
						bulk={bulk}
						setBulk={setBulk}
						setcampaignData={setcampaignData}
						TOSPercentage={TOSPercentage}
						defaultTargetAcos={defaultTargetAcos}
						HandleSelect={HandleSelect}
						setRowSelect={setRowSelect}
						rowSelect={rowSelect}
						setbestFlag={setbestFlag}
						bestFlag={bestFlag}
						bestArr={bestArr}
						bestCVRCPC={bestCVRCPC}
					/>
				</div>
			);
		},
		[campaignData, bestArr, bestCVRCPC]
	);


	const childHandler = (val) => {

		let data = {
			name: localStorage.getItem("radio"),
			profileId: profileId,
			type: arg.type,
			campaignNames: campaignNamesArr,
			fromDate: fromDate,
			toDate: toDate,
			filterIds: selectedOptions,
		}

		if (val == "DataTable") {
			data = {
				...data,
				filterType: "Date"

			}
		}
		else {
			if (orderSwitch) {

				data = {
					...data,
					filterType: "Orders"

				}
			}
			else {
				data = {
					...data,
					filterType: "Date"

				}
			}
		}
		ApiChild(data)
			.then((res) => {

				let oldData = campaignData
				if (res.isSuccess) {

					let oldSub = subval;
					dispatch(childBidHandler(subval))
					setsubval([...subval])
					if (res?.placementSubResult.length != 0) {
						if (bulk) {
							res?.placementSubResult.map(el => {
								oldData.map(item => {
									if (el.campaignId == item.campaignId) {
										item.placementsData = el.placementsData
										item.placementsData[0].targetAcos = Number(acos.TOS_ACOS)
										item.placementsData[1].targetAcos = Number(acos.ROS_ACOS)
										item.placementsData[2].targetAcos = Number(acos.PP_ACOS)

									}
								})
							})

						}
						else {
							oldData.map(item => {
								res?.placementSubResult?.find(el => {
									if (el.campaignId == item.campaignId) {
										item.placementsData = el.placementsData
										item.placementsData.map((record) => {
											if (record.placement == "Top of Search") {
												record.defaultRecord = item.defaultRecord.dynamicTOStargetAcos;
												record.dynamicTarget = item.defaultRecord.dynamicTOSTarget;

											} else if (record.placement == "Rest of Search") {
												record.defaultRecord = item.defaultRecord.dynamicROStargetAcos;
												record.dynamicTarget = item.defaultRecord.dynamicROSTarget;
											} else {
												record.defaultRecord = item.defaultRecord.dynamicPPtargetAcos;
												record.dynamicTarget = item.defaultRecord.dynamicPPTarget;
											}
										})
									}
								})

							})

						}

					}
					else {
						res?.dataTableResult.map(el => {
							oldData.map(item => {
								if (el.campaignId == item.campaignId) {
									item.subDataTable = el.subDataTable
								}
							})
						})
					}
					setcampaignData(oldData)
				}
			}).catch((err) => {
				Toast.error("Something went wrong");
			});
	}

	useEffect(() => {
		if (campaignIds?.length != 0) {
			childHandler(radioCheck)
		}
	}, [campaignIds, arg.type]);



	const handleRowExpandToggle = (expanded, row) => {

		if (expanded) {


			let data = JSON.parse(localStorage.getItem('ids')) || []
			data.push(row.campaignId)
			localStorage.setItem('ids', JSON.stringify([...new Set(data)]))

			setExpandedRows([...expandedRows, row]);
			setCampaignIds([...campaignIds, row.campaignName])
			setCampaignNamesArr([...campaignNamesArr, row.campaignId])
			// HandleSelect(row)
			// setTimeout(() => {
			//   HandleSelect(row)
			// }, 1000);
			// setaddrow(row)

		} else {

			setExpandedRows(
				expandedRows.filter((id) => id.campaignName !== row.campaignName)
			);
			setCampaignIds(campaignIds.filter((id) => id !== row.campaignName))
			setCampaignNamesArr(campaignNamesArr.filter((id) => id !== row.campaignId))

		}
	};


	const HandleSelect = (findRecord) => {

		if ((Number(findRecord.keywordBid) === Number(findRecord.newKeywordBid) &&
			Number(findRecord?.placementsData[0]?.placementModifier) === Number(findRecord?.placementsData[0]?.newbidAdjustment) &&
			Number(findRecord?.placementsData[1]?.placementModifier) === Number(findRecord?.placementsData[1]?.newbidAdjustment) &&
			Number(findRecord?.placementsData[2]?.placementModifier) === Number(findRecord?.placementsData[2]?.newbidAdjustment))) {
			setRowSelect((prevRowSelect) => prevRowSelect.filter(id => id !== findRecord.campaignId));

		}
		else {
			if (!rowSelect.includes(findRecord.campaignId)) {
				setRowSelect((prevRowSelect) => [...prevRowSelect, findRecord.campaignId]);
			}
		}

	}

	const handleExpandAllClick = () => {

		if (expandedRows.length === 0) {

			const allRowIds = campaignData.map((row) => row.campaignName);
			setCampaignIds(allRowIds)

			let data = JSON.parse(localStorage.getItem('ids')) || []
			let findarr = campaignData.map((row) => row.campaignId)
			let arr = data.concat(...findarr)
			localStorage.setItem('ids', JSON.stringify([...new Set(arr)]))


			setExpandedRows(campaignData);
			setCampaignNamesArr(findarr)
			localStorage.setItem('selectedItem', findarr)
			localStorage.removeItem('currentDataId')

		} else {

			setExpandedRows([]);
			setCampaignIds([])
			setCampaignNamesArr([])

		}
	};


	// -------- weekly table data --------------//


	// ---pagination ---//
	const handlePageChange = (page) => {
		setExpandedRows([])
		setArg({
			...arg,
			pageNo: page,
			perPage: perPage
		})
		getCampaignData({ pageNo: page, perPage: perPage });
		setSelectALL(false)
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		setArg({
			...arg,
			pageNo: page,
			perPage: newPerPage
		})

		localStorage.setItem('perPage', newPerPage);
		setPerPage(newPerPage)

		let data = {
			perPage: newPerPage,
			pageNo: page
		}

		getCampaignData(data);
		setExpandedRows([]);
	};

	const HandleRadio = (e) => {

		setradioCheck(e.target.value);
		localStorage.setItem("radio", e.target.value);

		if (campaignIds?.length != 0) {
			childHandler(e.target.value)
		}

	};



	// --------grp filter ----------//

	const getFilterData = async () => {
		let storeArr = [];
		let data = {
			profileId: profileId,
		};

		try {
			const e = await ApiCustomFilter(data);
			if (e?.isSuccess) {
				e.data.forEach((el) => {
					storeArr.push({
						key: el.filterName,
						value: el._id,
						// cat: el.searchType
					});
				});
				setfilterData(storeArr);
				setOptionName(storeArr)
			} else {
				Toast.error(e?.message);
				setfilterData([]);

			}
		} catch (error) {
			Toast.error("Something went wrong");
		}
	};
	useEffect(() => {

		const fetchData = async () => {
			await getFilterData();
		};
		fetchData();
	}, [profileId]);

	const filterApply = () => {
		setCampaignNamesArr([]);
		setRowSelect([]);
		localStorage.removeItem('ids')
		setArg({ ...arg, filterIds: selectedOptions });
		getCampaignData({ filterIds: selectedOptions });
	};

	const searchHandler = () => {
		setCampaignNamesArr([]);
		localStorage.removeItem("ids");
		setRowSelect([]);
		setArg({ ...arg, search: searchEL });
		getCampaignData({ search: searchEL });
	};


	const handleSaveDB = () => {

		let data;
		let sendPlacement = []
		let oldCampaign = campaignData;
		let findPlacement = oldCampaign.filter(x => x.placementsData);
		findPlacement.map(x => {

			sendPlacement.push({
				campaignId: x.campaignId,
				TOS_Acos: x.placementsData[0].targetAcos,
				ROS_Acos: x.placementsData[1].targetAcos,
				PP_Acos: x.placementsData[2].targetAcos,
				TOS_tagetCPCAmazon: Number(x.placementsData[0].tagetCPCAmazon),
				PP_tagetCPCAmazon: Number(x.placementsData[2].tagetCPCAmazon),
				ROS_tagetCPCAmazon: Number(x.placementsData[1].tagetCPCAmazon),
				newKeywordBid: Number(x.newbidAdjustment),

			})
		})
		data = {
			profileId: profileId,
			applyOn: "DB",
			timezone: localStorage.getItem('antonTimezone'),
			placementData: sendPlacement
		}

		ApiSaveToDb(data)
			.then((res) => {
				if (res.isSuccess) {
					getCampaignData()
					Toast.success(res?.message);
				}
				else {
					Toast.error(res?.message);
				}
			}).catch((err) => {
				Toast.error("Something went wrong");
			});

	}

	const handleSelectAllFilter = (selectedList, selectedItem) => {
		setSelectedOptions(selectedList.map(x => x.value))
	};

	const handleRemove = (selectedList, removedItem) => {
		setSelectedOptions(selectedList.map(x => x.value))
	};

	const handleSaveAmazon = () => {

		let sendPlacement = [];
		let oldCampaign = campaignData;
		let data;
		let getId = rowSelect

		let findPlacement = oldCampaign.filter(campaign => getId.includes(campaign.campaignId) && campaign?.placementsData);


		findPlacement.map(x => {

			sendPlacement.push({
				keywordBid: x.keywordBid || 0,
				campaignName: x.campaignName,
				campaignId: x.campaignId,
				newKeywordBid: Number(x.newbidAdjustment),
				TOS_Acos: x.placementsData[0].targetAcos,
				TOS_Cpc: x.placementsData[0].targetCPC,
				TOS_modifire: x.placementsData[0]?.newbidAdjustment == undefined ? 0 : Number(x.placementsData[0].newbidAdjustment),
				ROS_Acos: x.placementsData[1].targetAcos,
				ROS_Cpc: x.placementsData[1].targetCPC,
				PP_Acos: x.placementsData[2].targetAcos,
				PP_Cpc: x.placementsData[2].targetCPC,
				PP_modifire: x.placementsData[2]?.newbidAdjustment == undefined ? 0 : Number(x.placementsData[2].newbidAdjustment),
				TOS_tagetCPCAmazon: Number(x.placementsData[0].tagetCPCAmazon),
				PP_tagetCPCAmazon: Number(x.placementsData[2].tagetCPCAmazon),
				ROS_tagetCPCAmazon: Number(x.placementsData[1].tagetCPCAmazon),
				ROS_modifire: x.placementsData[1]?.newbidAdjustment == undefined ? 0 : Number(x.placementsData[1].newbidAdjustment),
				old_TOS_modifire: x.placementsData[0]?.placementModifier,
				old_ROS_modifire: x.placementsData[1]?.placementModifier,
				old_PP_modifire: x.placementsData[2]?.placementModifier,


			})
		})
		data = {
			profileId: profileId,
			applyOn: "Amazon",
			timezone: localStorage.getItem('antonTimezone'),
			campaignData: sendPlacement
		}

		ApiStore(data)
			.then((res) => {
				if (res.isSuccess) {
					Toast.success(res.message);
					getCampaignData()
				} else {
					Toast.error(res.message);
				}
			})
			.catch((err) => {
				Toast.error("somthing went wrong!!");
			});
	}

	const handleSort = (column, sortDirection) => {
		let field = ''
		if (column.name == 'Target Acos') {
			field = 'targetAcos'
		}
		else if (column.name == 'Target CPC') {
			field = 'targetCPC'
		}
		else if (column.name == 'Campaigns') {
			field = 'campaignName'
		}
		else if (column.name == 'Target CPC') {
			field = 'targetCPC'
		}
		else if (column.name == 'Bid Adjustment') {
			field = 'keywordBid'
		}
		else if (column.name == 'New Bid Adjustment') {
			field = 'newbidAdjustment'
		}
		else if (column.name == 'Impressions') {
			field = 'totalImpressions'
		}
		else if (column.name == 'Clicks') {
			field = 'totalClicks'
		}
		else if (column.name == 'CTR%') {
			field = 'totalCtr'
		}
		else if (column.name == 'Spend') {
			field = 'totalSpend'
		}
		else if (column.name == 'Sales') {
			field = 'totalSales'
		}
		else if (column.name == 'Orders') {
			field = 'totalOrders'
		}
		else if (column.name == 'Units') {
			field = 'totalOrders'
		}
		else if (column.name == 'CVR%') {
			field = 'totalCvr'
		} else if (column.name == 'ACOS%') {
			field = 'totalAcos'
		} else if (column.name == 'CPC') {
			field = 'totalCpc'
		} else if (column.name == 'ROAS') {
			field = 'totalRoas'
		}
		setfieldName(field)
		let sorting = ''
		if (sortDirection == 'asc') {
			sorting = '1'
		}
		else {
			sorting = '-1'
		}
		setsortType(sorting)
		getCampaignData({ fieldName: field, sortType: sorting })
	};


	const HandleManageCampaign = () => {
		let filterData = JSON.parse(localStorage.getItem('ids'));
		// getCampaignData({ campaignIds: filterData ,isManage: false, })
		let data = {
			isManage: false,
			campaignIds: filterData,
			profileId: profileId,
			type: "Sponsored Products"
		};

		ApiUnmange(data)
			.then((res) => {
				if (res.isSuccess) {
					Toast.success(res.message);
					getCampaignData()
				}
			}).catch((err) => {
				Toast.error("somthing went wrong!!");
			});
	}

	const handleApplyBestCpc = () => {

		setBestCVRCPC([])
		let oldCampaign = campaignData;
		let getId = JSON.parse(localStorage.getItem('ids'));

		let findPlacement = oldCampaign.filter(campaign => getId.includes(campaign.campaignId) && campaign?.placementsData);

		setBestArr(findPlacement.map(x => x.campaignId))

	};

	const handleOtherFilter = (el) => {
		setCampaignNamesArr([]);
		localStorage.removeItem('ids')
		setRowSelect([]);
		setCampaignFilterName(el.value);
		setArg({ ...arg, searchType: el.value })
		getCampaignData({ searchType: el.value, perPage: perPage, pageNo: 1 });
	}

	return (
		<>
			<div className="data_content mt-0 mb-1 mb-md-2 ">
				<div className="data_table campaign_table scrollable_table">
					<div className="campaign_btn main_campaign_res">
						<div>
							<button onClick={handleExpandAllClick} className="expand-btn w-100">
								{expandedRows?.length === campaignData?.length
									? "Collapse All"
									: "Expand All"}
							</button>
						</div>
						{/* <div className="expand_btn col col-md-4 p-1">
                  <button className="expand-btn w-100" onClick={() => { setaddModalFlag(true) }}>
                    Bulk Target ACOS
                  </button>
                  <BulkTarget
                    setBulk={setBulk}
                    addModalFlag={addModalFlag}
                    setaddModalFlag={setaddModalFlag}
                    getCampaignData={getCampaignData}
                    target={target}
                    setTarget={setTarget}
                    acos={acos}
                    setAcos={setAcos}
                    setExpandedRows={setExpandedRows}
                  />
                </div> */}
						<div>
							<div className="radio-buttons h-100">
								<input
									type="radio"
									className="mx-1"
									id="Placement"
									name="fav_language"
									checked={radioCheck == "Placement"}
									value="Placement"
									onChange={(e) => HandleRadio(e)}
								/>
								<label for="Placement" className="">
									Placement
								</label>
								<input
									type="radio"
									className="mx-1"
									id="DataTable"
									name="fav_language"
									checked={radioCheck == "DataTable"}
									value="DataTable"
									onChange={(e) => HandleRadio(e)}
								/>
								<label for="DataTable">DataTable</label>
							</div>
						</div>

						<div className="search-btn">
							<div className="inner-search-btn">
								<input
									type="search"
									class="form-control"
									placeholder="Search Campaign"
									onChange={(e) => setsearchEL(e.target.value)}
								/>
								<button class="btn btn-primary" onClick={() => searchHandler()}>
									<i class="fas fa-search"></i>
								</button>

							</div>
						</div>
						<div>
							<div className="filter_select p-1 position-relative">
								<Multiselect
									options={optionName} // Options to display in the dropdown
									displayValue="key"
									className="h-75"
									showCheckbox={true}
									onSelect={handleSelectAllFilter}
									onRemove={handleRemove}
								/>
								<button className="apply_btn" onClick={() => filterApply()}>
									Apply
								</button>

							</div>
						</div>


						<div className={`vertical_table `}>
							{campaignFilterData?.map((el) => {
								return (
									<>
										<button
											className={`${campaignFilterName == el.value ? "activeFilter" : ""
												}`}
											onClick={() => {

												handleOtherFilter(el)
											}}
										>
											{el.label}
										</button>
									</>
								);
							})}
						</div>

					</div>

					<div className="second_row_btn" >

						<div>
							<button className="expand_btn w-100" onClick={() => handleApplyBestCpc()}>Apply Best CPC</button>
						</div>
						<div>
							<button className="expand_btn w-100" onClick={() => HandleManageCampaign()}>Manage Campaign</button>
						</div>

						<div>
							<button className="expand_btn w-100" onClick={() => handleSaveDB()}>Save In DB</button>
						</div>
						<div>
							<button className="expand_btn w-100" onClick={() => handleSaveAmazon()}>Apply On Amazon</button>
						</div>

					</div>

					<DataTable
						title=""
						columns={columns}
						data={campaignData}
						onSort={handleSort}
						expandableRows
						key={perPage}
						expandableRowsComponent={ExpandedComponent}
						expandableIcon={customExpandIcon}
						onRowExpandToggled={handleRowExpandToggle}
						isRowExpandable
						expandableRowExpanded={(row) => {
							return campaignIds?.includes(row?.campaignName)
						}}
						pagination
						paginationTotalRows={totalRecords}
						paginationPerPage={perPage}
						paginationRowsPerPageOptions={paginationRowsPerPageOptions}
						paginationServer
						onChangePage={handlePageChange}
						onChangeRowsPerPage={handlePerRowsChange}
						keyField="campaignId"
					/>
				</div>
			</div>
		</>
	);
}

export default CampaignTable;
