import React from 'react'
import Filter from '../../../helper/filter/Filter';

import { useState } from 'react';
import { DatePicker, DateRangePicker } from 'rsuite';
import { ApiGetUtilityHistory, ApiUtilityGenerateReport } from '../../../api-wrapper/campaign/ApiCampaignName';
import Toast from '../../../helper/toast/Toast';
import moment from 'moment-timezone';
import DataTable from 'react-data-table-component';
import { useEffect } from 'react';
import success from '../../../assets/images/success.svg'
import { Spinner } from 'react-bootstrap';


function Synchronize() {

    const [campaignId, setcampaignId] = useState("")
    const [fromDate, setfromDate] = useState();
    const [toDate, settoDate] = useState();
    const [profileId, setprofileId] = useState(localStorage.getItem("profileId") || "")
    const [history, sethistory] = useState([])
    const [date, setDate] = useState([]);
    const [loader, setloader] = useState(false)

    const [totalRecords, settotalRecords] = useState(0);
    const [perPage, setPerPage] = useState(10)
    const [flag, setflag] = useState(false)
    const [expandedRows, setExpandedRows] = useState([]);
    const [arg, setArg] = useState({
        pageNo: 1,
        perPage: perPage
    });



    const campaignIdHandler = (e) => {
        setcampaignId(e)
    }

    const dateHandler = (e) => {
        if (e) {
            setfromDate(e[0]);
            settoDate(e[1])
        }
    }

    useEffect(() => {
        GetUtilityHistory()
        let cls = document.querySelectorAll('.jydAqc')
        cls.forEach(element => {
            element.style.display = 'none';
        });
    }, [])


    const UtilityGenerateReport = async () => {
        const data = {
            profileId: profileId,
            startDate: date[0],
            endDate: date[1]
        }
        if (date.length == 2) {
            setloader(true)
        }
        await ApiUtilityGenerateReport(data)
            .then((e) => {
                if (e?.isSuccess) {
                    setloader(false)
                    Toast.success(e?.message);
                } else {
                    Toast.error(e?.message);
                }
            })
            .catch((e) => {
                Toast.error("Somthing went wrong");
            });
    }

    useEffect(() => {
        if (flag == true) {
            GetUtilityHistory()
        }
    }, [flag])


    const dateChangeHandler = (e) => {
        let arr;
        if (e?.length == 0) {
            arr = [];
        } else {
            arr = [
                moment(e && e[0]).format("YYYY-MM-DD"),
                moment(e && e[1]).format("YYYY-MM-DD"),
            ];
        }

        setDate(arr);
    };

    const columns = [
        {
            name: "Last Run",
            sortable: true,
            selector: (row) => row.lastRun,
            cell: (e) => (
                <div>
                    {e.lastRun ? (
                        <div className="d-flex align-items-center success_img">
                            <img src={success} className="me-1" width={'15px'} />
                            <p>{e.lastRun}</p>
                        </div>
                    ) : (
                        <>-</>
                    )}
                </div>
            ),
        },
        {
            name: "No. of Days",
            sortable: true,
            selector: (row) => row.days,
            cell: (e) => {
                return <div className='text-center'>
                    {e.days}
                </div>;
            },
        },
        {
            name: "Type",
            sortable: true,
            selector: (row) => row.type,
            cell: (e) => {
                return <div className='text-center'>
                    {e.type}
                </div>;
            },
        },
        {
            name: "Status",
            sortable: true,
            selector: (row) => row.status,
            cell: (e) => {
                return <div className={`text-center ${e.status == 'COMPLETED' ? 'text-success' : e.status == 'PENDING' ? 'text-warning' : ''}`}>
                    {e.status}
                </div>;
            },
        }
    ]


    const GetUtilityHistory = async (val) => {
        const data = {
            profileId: profileId,
            ...arg,
            ...val
        }
        await ApiGetUtilityHistory(data)
            .then((e) => {
                if (e?.isSuccess) {
                    sethistory(e.data)
                    settotalRecords(e.totalRecords)
                } else {
                    Toast.error(e?.message);
                }
            })
            .catch((e) => {
                Toast.error("Somthing went wrong");
            });
    }

    const handlePageChange = (page) => {
        setExpandedRows([])
        GetUtilityHistory({ pageNo: page, perPage: perPage });
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        let perPageValue;

        if (isNaN(newPerPage)) {
            perPageValue = totalRecords;
        } else {
            perPageValue = newPerPage;
        }
        setPerPage(perPageValue)
        GetUtilityHistory({ pageNo: page, perPage: perPageValue });
        setExpandedRows([]);
    };





    return (
        <>
            <div className='bg-white'>
                <Filter campaignIdHandler={campaignIdHandler} dateHandler={dateHandler} />
            </div>
            <div className='middle_container addfilter_container'>
                <div className='data_content data_content_btn'>
                    <div className='data_modal_btn'>
                        <h5>Synchronize</h5>
                    </div>
                    <div className=' py-4 px-3 setting-div'>

                        <div className='setting_inner row row-cols-1 row-cols-md-3 py-2 d-flex justify-content-center'>

                            <div className='col col-12 col-md-8 col-lg-5 col-xl-3'>

                                <div className="inner_filer date_range d-flex align-items-center">
                                    <label className='w-25'>Select-Date   :</label>
                                    <DateRangePicker
                                        className="filter_date w-75"
                                        placeholder="Select Date"
                                        onChange={(e) => dateChangeHandler(e)}
                                        format="yyyy-MM-dd"
                                        value={[
                                            date &&
                                            date[0] !== undefined &&
                                            moment(date[0], "YYYY-MM-DD").toDate(),

                                            date &&
                                            date[1] !== undefined &&
                                            moment(date[1], "YYYY-MM-DD").toDate(),
                                        ]}
                                    />
                                </div>

                            </div>
                            <div className='col col-12 col-md-3 col-lg-2 text-center text-lg-start d-flex'>
                                <button className='buttons' onClick={() => UtilityGenerateReport()}>Synchronize</button>
                                {
                                    loader &&
                                    (
                                        <div className='position-relative'>
                                            <Spinner animation="border" role="status" className='top-0 ms-2' variant='primary'>
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        </div>
                                    )
                                }
                            </div>
                        </div>


                        <div className='setting_inner mt-3 d-block'>
                            <DataTable
                                title=""
                                columns={columns}
                                data={history}
                                striped={true}
                                pagination
                                paginationTotalRows={totalRecords}
                                paginationPerPage={perPage}
                                paginationRowsPerPageOptions={[10, 25, 50, 100, 'All']}
                                paginationServer
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handlePerRowsChange}
                            />

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Synchronize