import axios from "axios";

const api = `${process.env.REACT_APP_API_BASE}/api/placement/`
const authToken = localStorage.getItem('sellerToken')
const headers = { Authorization: `Bearer ${authToken}`, 'Content-Type': 'application/json' };

const ApiPlacementListByCampaign = (data) => {
    return axios.post(`${api}getPlacementByCampaign`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}
const ApiStore = (data) => {
    return axios.post(`${api}updateCampaign`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}

const ApiPlacementListByCampaignLogHistory=(data)=>{
    return axios.post(`${api}campaignLogHistory`, data, { headers })
    .then(res => res.data).catch(res => res.data)
}
export { ApiPlacementListByCampaign, ApiStore ,ApiPlacementListByCampaignLogHistory}