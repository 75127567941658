import AddFilter from '../layout/views/addfilter/AddFilter';
import Advertising from '../layout/views/advertising/Advertising';
import Portfolios from '../layout/views/advertising/portfolios/Portfolios';
import Campaigns from '../layout/views/advertising/campaigns/Campaigns';
import Dashboard from '../layout/views/sp/Dashboard';
import ParentAsin from '../layout/views/advertising/parentAsin/ParentAsin';
import ChildAsin from '../layout/views/advertising/childAsin/ChildAsin';
import Performance from '../layout/views/performance/Performance';
import PeformaceBrand from '../layout/views/performance/brand/Brand';
import PeformaceCampaigns from '../layout/views/performance/campaign/Campaigns';
import PeformaceParentAsin from '../layout/views/performance/parentAsin/ParentAsin';
import PeformaceChildAsin from '../layout/views/performance/childAsin/ChildAsin';
import CampaignsAutomation from '../layout/views/advertising/campaigns/Campaigns';
import Synchronize from '../layout/views/synchronize/Synchronize';
import ACOS from '../layout/views/settings/acos/ACOS';
import Other from '../layout/views/settings/other/Other';
import UnmanageCampaign from '../layout/views/settings/unmanage/UnmanageCampaign';
import SponsoredBrand from '../layout/views/sb/Index';
import SponsoredDisplay from '../layout/views/sd/Index';
import History from '../layout/views/otherHistory/History';
const PagesRoutes = [
    {
        path: "/",
        component: <Dashboard />,
        exact: true,
    },
    {
        path: "/sponsored-brand",
        component: <SponsoredBrand />,
        exact: true,
    },
    {
        path: "/sponsored-display",
        component: <SponsoredDisplay />,
        exact: true,
    },
    {
        path: "/filter",
        component: <AddFilter />,
    },
    {
        path: "",
        exact: true,
    },
    {
        path: "/advertising/history",
        component: <Portfolios />,
        exact: true,
    },
    {
        path: "/advertising/campaign",
        component: <CampaignsAutomation />,
        exact: true,
    },
    {
        path: "/advertising/parentAsin",
        component: <ParentAsin />,
        exact: true,
    },
    {
        path: "/advertising/childAsin",
        component: <ChildAsin />,
        exact: true,
    },
    {
        path: "/performance",
        component: <Performance />,
        exact: true,
    },
    {
        path: "/performance/brand",
        component: <PeformaceBrand />,
        exact: true,
    },
    {
        path: "/performance/campaign",
        component: <PeformaceCampaigns />,
        exact: true,
    },
    {
        path: "/performance/parentAsin",
        component: <PeformaceParentAsin />,
        exact: true,
    },
    {
        path: "/performance/childAsin",
        component: <PeformaceChildAsin />,
        exact: true,
    },
    // {
    //     path: "/settings",
    //     component: <Settings />,
    //     exact: true,
    // },
    {
        path: "/settings/acos",
        component: <ACOS />,
        exact: true,
    },
    {
        path: "/settings/other",
        component: <Other />,
        exact: true,
    },

    {
        path: "/synchronize",
        component: <Synchronize />,
        exact: true,
    },
    {
        path: "/settings/unmange-campaign",
        component: <UnmanageCampaign />,
        exact: true,
    },
    {
        path: "/sb-sd-history",
        component: <History />,
        exact: true,
    },
]
export default PagesRoutes;

