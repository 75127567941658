import React from "react";
import { useState } from "react";
import Filter from "../../../../helper/filter/Filter";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { ApiPlacementListByCampaign, ApiStore } from "../../../../api-wrapper/advertising/ApiAdvertising";
import Toast from "../../../../helper/toast/Toast";
import { handleLoader } from "../../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import ChildCampaign from '../campaigns/ChildCampaign';
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useCallback } from "react";
import { useRef } from "react";
function CampaignsAutomation() {
  const [subval, setsubval] = useState([])

  const [fromDate, setfromDate] = useState(
    localStorage.getItem("fromDate") || ""
  );
  const [toDate, settoDate] = useState(localStorage.getItem("toDate") || "");
  const [profileId, setprofileId] = useState(
    localStorage.getItem("profileId") || ""
  );
  const [placementsData, setplacementsData] = useState([]);
  const [campaignIds, setCampaignIds] = useState([]);

  const dispatch = useDispatch();

  const [campaignData, setcampaignData] = useState([]);


  const columns = [
    {
      name: "Target Acos",
      sortable: true,
      selector: (row) => row.targetAcos,
      cell: (e) => {
        return <>{`-`}</>;
      },
    },
    {
      name: "Target CPC",
      sortable: true,
      selector: (row) => row.targetCPC,
      cell: (e) => {
        return <>{`-`}</>;
      },
    },

    {
      name: "Campaigns",
      selector: (row) => row.campaignName,
      width: "250px",
      sortable: true,
    },

    {
      name: "Bid Adjustment",
      sortable: true,
      selector: (row) => row.keywordBid,
      cell: (e, rowIndex) => {
        return `$${e.keywordBid}`;
      },
    },

    {
      name: "New Bid Adjustment",
      selector: (row) => row.newbidAdjustment,
      sortable: true,
      cell: (e, rowIndex) => {
        let find = subval.find(x => x.id == e.campaignId)
        return `$${find?.val == undefined || find?.val == NaN ? 0 : find?.val}`;

      },
    },
    {
      name: "Impressions",
      selector: (row) => row.totalImpressions,

      sortable: true,
      // width: "100px",
    },
    {
      name: "Clicks",
      selector: (row) => row.totalClicks,
      sortable: true,
      // width: "70px",
    },
    {
      name: "CTR%",
      sortable: true,
      selector: (row) => row.totalCtr,
      cell: (e) => `${e.totalCtr}%`,
    },
    {
      name: "Spend",
      sortable: true,
      selector: (row) => row.totalSpend,
      cell: (e) => `$${e.totalSpend}`,
    },
    {
      name: "Sales",
      sortable: true,
      selector: (row) => row.totalSales,
      cell: (e) => `$${e.totalSales}`,
    },
    {
      name: "Orders",
      selector: (row) => row.totalOrders,
      sortable: true,

    },
    {
      name: "Units",
      selector: (row) => row.totalOrders,
      sortable: true,

    },

    {
      name: "CVR%",
      sortable: true,
      selector: (row) => row.totalCvr,
      cell: (e) => `${e.totalCvr}%`,
    },

    {
      name: "ACOS%",
      sortable: true,
      selector: (row) => row.totalAcos,
      cell: (e) => `${e.totalAcos}%`,
    },

    {
      name: "CPC",
      sortable: true,
      selector: (row) => row.totalCpc,
      cell: (e) => `$${e.totalCpc}`,
    },

    {
      name: "ROAS",
      selector: (row) => row.totalRoas,
      sortable: true,
    },
    {
      name: "Sponsored Rank",
      selector: (row) => "-",
      sortable: true,
      width: "40px",
    },
    {
      name: "Organic Rank ",
      selector: (row) => "-",
      sortable: true,
      width: "40px",
    },
  ]

  const campaignIdHandler = (e) => {
    // setcampaignId(e);
  };

  const dateHandler = (e) => {
    if (e) {
      setfromDate(e[0]);
      settoDate(e[1]);
    }
  };

  useEffect(() => {
    localStorage.setItem("fromDate", fromDate);
    localStorage.setItem("toDate", toDate);
  }, [fromDate, toDate]);

  const getCampaignList = async () => {
    if (fromDate && toDate) {
      dispatch(handleLoader(true));
      const data = {
        isManage: false,
        profileId: profileId,
        fromDate: fromDate,
        toDate: toDate,
        pageNo: 1,
        perPage: 2500,
        type: 2,
        filterIds: [],
        searchType: "",
        search: "",
      };
      await ApiPlacementListByCampaign(data)
        .then((e) => {
          if (e?.isSuccess) {
            dispatch(handleLoader(false));
            setcampaignData(e.placementsData);
            setsubval([])
          } else {
            dispatch(handleLoader(false));
            Toast.error(e?.message);
          }
        })
        .catch((e) => {
          dispatch(handleLoader(false));
          Toast.error("Somthing went wrong");
        });
    }
  };

  useEffect(() => {
    getCampaignList();
  }, [fromDate, toDate]);



  const handleRowExpandToggle = (expanded, row) => {

    if (expanded) {
      setExpandedRows([...expandedRows, row]);
      setCampaignIds([...campaignIds, row.campaignId])


    } else {

      setExpandedRows(
        expandedRows.filter((id) => id.campaignId !== row.campaignId)
      );
      setCampaignIds(campaignIds.filter((id) => id !== row.campaignId))

    }
  };

  const isRowExpanded = (row) => expandedRows.includes(row.campaignId);

  const CustomCollapsedIcon = () => <AiOutlinePlus />;
  const CustomExpandedIcon = () => <AiOutlineMinus />;
  const [expandedRows, setExpandedRows] = useState([]);

  const customExpandIcon = {
    collapsed: <CustomCollapsedIcon />,
    expanded: <CustomExpandedIcon />,
  };

  const ExpandedComponent = useCallback(({ data }) => {
    return (
      <div className="sub_table campaign_table campaign_sub" key={data.campaignId}>
        <ChildCampaign
          placementData={data?.placementsData}
          setsubval={setsubval}
          subval={subval}
        />
      </div>
    );
  }, [campaignData]);

  const handleAmazon = (name) => {
    let campaignsend = campaignData[0];
    let data = {
      campaignId: campaignsend.campaignId,
      profileId: profileId,
      newKeywordBid: subval[0]?.val,
      timezone: localStorage.getItem('antonTimezone'),
      TOS_Acos: campaignsend.placementsData[0].targetAcos,
      TOS_Cpc: campaignsend.placementsData[0].targetCPC,
      TOS_modifire: campaignsend.placementsData[0].newbidAdjustment,
      PP_Acos: campaignsend.placementsData[1].targetAcos,
      PP_Cpc: campaignsend.placementsData[1].targetCPC,
      PP_modifire: campaignsend.placementsData[1].newbidAdjustment,
      ROS_Acos: campaignsend.placementsData[2].targetAcos,
      ROS_Cpc: campaignsend.placementsData[2].targetCPC,
      applyOn: name
    }
    ApiStore(data)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);
          getCampaignList()
        } else {
          Toast.error(res.message);
        }

      })
      .catch((err) => {
        Toast.error("something went wrong!");
      });
  }

  return (
    <>
      <div className="bg-white">
        <Filter
          campaignIdHandler={campaignIdHandler}
          dateHandler={dateHandler}
          fromDate={fromDate}
          toDate={toDate}
        />
      </div>
      <div className="middle_container heading_none">
        <div className="data_content ">
          <div className="data_table campaign_table scrollable_table">
            <div className="campaign_filter_data campaign_btn campaign_filter ms-auto">
              <div className="expand_btn">
                <button className="expand-btn" onClick={() => handleAmazon('DB')}>Save In DB</button>
              </div>

              <div className="expand_btn">
                <button className="expand-btn" onClick={() => handleAmazon('Amazon')}>Apply In Amazon</button>
              </div>
            </div>

            <DataTable
              title=""
              columns={columns}
              data={campaignData}
              expandableRows
              expandableRowsComponent={ExpandedComponent}
              expandableIcon={customExpandIcon}
              onRowExpandToggled={handleRowExpandToggle}
              isRowExpandable={(row) => true}
              expandableRowExpanded={isRowExpanded}
              keyField="campaignId"

            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CampaignsAutomation;
