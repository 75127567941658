import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import axios from "axios";
import store from './redux/store'
import { handleLoader } from './redux/action';

const { dispatch } = store;
let requestCount = 0;

axios.interceptors.request.use(
  (config) => {

    if (config.url.includes('utilityGenerateReport')) {
      dispatch(handleLoader(false))
    }
    else {
      requestCount++;
      dispatch(handleLoader(true));
    }
    // Increment the request count for every API request

    return config;
  },
  error => {
    // Decrement the request count if a request fails
    requestCount--;
    if (requestCount === 0) {
      dispatch(handleLoader(false));
    }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {

    // Decrement the request count for every API response
    requestCount--;
    if (requestCount === 0) {
      dispatch(handleLoader(false));
    }

    if (response.data.isSuccess === false && response.data.message === "Invalid token") {
      localStorage.removeItem("sellerToken");
      window.location.href = "/login";
    } else if (response.data.isSuccess === false && response.data.message === "Token Expired") {
      localStorage.removeItem("sellerToken");
      window.location.href = "/login";
    } else {
      return response;
    }
  },
  (error) => {
    // Decrement the request count if a response fails
    requestCount--;
    if (requestCount === 0) {
      dispatch(handleLoader(false));
    }

    if (error.response) {
      return Promise.reject(error);
    } else if (error.request) {
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

reportWebVitals();
