import Spinner from 'react-bootstrap/Spinner';

import React from 'react'

const Loader = () => {
    return (
        <div className='loader'> <Spinner animation="border" className='spinner-border' /></div>
    );
}

export default Loader
