import React, { createContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
export const ProfileContext = createContext();

export function UseContext({ children }) {
    const { profileInfo } = useSelector(state => state.data)
    const [viewFilter, setviewFilter] = useState();
    const [limitValue, setlimitValue] = useState();
    const [minTargetCPC, setminTargetCPC] = useState();
    const [maxTargetCPC, setmaxTargetCPC] = useState();
    const [orderSwitch, setOrderSwitch] = useState(false);
    const [orderVal, setOrderVal] = useState();
    const [sideFilterShow, setsideFilterShow] = useState({
        campaignFilter: false,
        customFilter: false
    });

    let data = {
        viewFilter,
        setviewFilter,
        sideFilterShow,
        minTargetCPC,
        setminTargetCPC,
        maxTargetCPC,
        setmaxTargetCPC,
        limitValue,
        setlimitValue,
        orderSwitch,
        setOrderSwitch,
        orderVal,
        setOrderVal
    }

    return (
        <ProfileContext.Provider value={data}>
            {children}
        </ProfileContext.Provider>
    )
}